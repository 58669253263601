<template>
  <main class="h-screen bg-black">
    <div class="w-full h-full flex flex-col items-center justify-center">
      <img src="@/assets/images/logo.png" />
      <h1 class="text-3xl font-bold mb-6 text-white">
        {{ error.message }}
      </h1>
      <h1 class="text-2xl font-bold mb-6 text-white">
        {{ error.description }}
      </h1>
      <h1 class="text-xl font-bold mb-6 text-white">v{{ version }}</h1>
    </div>
  </main>
</template>

<script>
export default {
  name: "Error",
  props: {
    error: Object,
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    };
  },
};
</script>

<style></style>
